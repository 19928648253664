import React, { useEffect, useState } from 'react';
import { Dropdown, Input, Layout, Menu, Space, Card, Button, Row, Col, Image, FloatButton} from "antd";
import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import Constants from '../app/Constants';
import '../Home.css';
import Slider from '../components/Slider';
import ProductItem from '../components/ProductItem';
import {
 
    WhatsAppOutlined,
  } from "@ant-design/icons";
const { Header, Footer } = Layout;

const Home = (props) => {
  const [productos, setProductos] = useState([]);
  const [sliders, setSliders] = useState([]);

  const getproducts = () => {
    fetch(
      `${Constants.URL}/items/products?sort=-createdAt&limit=12&fields=*.*.*.*.*`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setProductos(response.data);
      })
      .catch((error) => console.error("error"));
  };

  const getHomedata = () => {
    fetch(
      `${Constants.URL}/items/sliders?fields=*.*.*.*.*`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setSliders(response.data);
      })
      .catch((error) => console.error("error"));
  };

  useEffect(() => {
    getproducts();
    getHomedata();
  }, []);

  return (
    <>
   <Row style={{ height: 60 }} justify="center" align="middle">
        <Col span={4}></Col>
        <Col span={8}>
       
          <Image
            preview={false}
            style={{height: 55}}
            src={`${Constants.URL}/assets/8d972036-c809-4f03-8bc4-bac68e9cd880`}
          />
        </Col>
        <Col span={12}>320 373 38 94 - 310 772 52 77</Col>
      </Row>
      <Header
        style={{
          background: "#000000",
          flexDirection: "row",
        }}
      >
        <Menu
          style={{
            background: "#000000",
          }}
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
        >
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            style={{ flexGrow: 1, background: "#000000" }}
          >
            <Menu.Item key="1">Inicio</Menu.Item>

            <Dropdown
              trigger={["click"]}
              menu={{
                productos,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>Marcas</Space>
              </a>
            </Dropdown>

            <Menu.Item key="3">Contactenos</Menu.Item>
          </Menu>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              placeholder="Buscar"
              prefix={<SearchOutlined />}
              style={{ marginRight: "16px" }}
            />
          </div>
        </Menu>
        <span style={{ textAlign: "rigth" }}>Buscador</span>
      </Header>
   <Slider sliders={sliders} />
   <h2 style={{ textAlign: "center" }}>Lo Ultimo</h2>
      <div style={{ display: 'flex', marginTop: '20px', flexWrap: 'wrap', justifyContent: 'center', margin: '20px 0', gap: '20px' }}>
      {productos.map((producto) => {
          console.log(producto);
            return (
              <Col  key={producto.id} className="gutter-row" span={6}>
                <ProductItem producto={producto} />
              </Col>
            );
        })
        }
      </div>
      <div className="container" style={{ textAlign: 'center', justifyContent: 'center' }}>
        <h1>Autopartes y Repuestos para Renault</h1>
        <p>
          Somos importadores y distribuidores especializados en repuestos para Renault y otras marcas. Ofrecemos repuestos de alta calidad, tanto originales como homologados, con garantía de fabricación. Contamos con una sólida experiencia de más de 14 años en el sector, trabajando con los mejores proveedores en Colombia, Estados Unidos, Europa, Japón y Brasil.
        </p>
        
        <div className="services">
          <h2>Nuestros Servicios:</h2>
          <ul>
            <li><strong>Repuestos para Renault:</strong> Importamos repuestos desde Francia, España, Corea del Sur y Colombia (SOFASA).</li>
            <li><strong>Repuestos para Mazda:</strong> Distribuimos partes de Japón, Malasia, Corea del Sur y Colombia (CCA).</li>
            <li><strong>Repuestos para Chevrolet:</strong> Disponibles desde Brasil, Corea del Sur y Colombia (GM CHEVROLET).</li>
            <li><strong>Repuestos para Ford:</strong> Provenientes de Ford USA, Brasil, México, Corea del Sur y Colombia (Ford COLOMBIA).</li>
          </ul>
        </div>
        
        <h2>¿Por Qué Elegirnos?</h2>
        <ul>
          <li><strong>Garantía de Fabricación:</strong> Todos nuestros productos cuentan con garantía.</li>
          <li><strong>Cobertura Nacional e Internacional:</strong> Distribuimos repuestos a nivel nacional e internacional.</li>
          <li><strong>Importación Exclusiva:</strong> Servicio especializado para repuestos de vehículos de gama alta y pedidos especiales.</li>
          <li><strong>Asesoría Profesional:</strong> Nuestro equipo de representantes está listo para ofrecer asesoría experta sobre repuestos.</li>
          <li><strong>Entrega a Domicilio:</strong> En Bogotá, ofrecemos entrega a domicilio sin costo adicional.</li>
        </ul>
        
        <div className="contact-info">
          <h2>Contáctenos:</h2>
          <p>
            Para más información o para realizar un pedido, contáctenos en{' '}
            <a href="mailto:info@carwestautopartes.com">info@carwestautopartes.com</a>. Estamos ubicados en Bogotá, Colombia.
          </p>
        </div>
      </div>
      <Footer style={{ height: 100, background: "#007bff", textAlign: "center", padding: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <FloatButton.Group shape="square" style={{ right: 94 }}>
          <a target="_blank" href="https://wa.me/573203733894">
            <FloatButton
              type="green"
              style={{ color: "green" }}
              icon={<WhatsAppOutlined />}
            />
          </a>

          <a target="_blank" href="https://wa.me/573107725277">
            <FloatButton
              type="green"
              style={{ color: "green" }}
              icon={<WhatsAppOutlined />}
            />
          </a>
          <FloatButton.BackTop visibilityHeight={0} />
        </FloatButton.Group>
        <p style={{ fontSize: "18px", color: "white", fontWeight: "bold" }}>Copyright 2023 CarWest Autopartes. Todos los derechos reservados.</p>
      </Footer>
    </>
  );
};
const contentStyle = {
    margin: 0,
    height: '500px',
    color: '#fff',
    lineHeight: '500px',
    textAlign: 'center',
    background: '#364d79',
  }
export default Home;
