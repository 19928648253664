import React from "react";
import { Carousel } from "antd";
import Constants from "../app/Constants";
const contentStyle = {
 
    
};
const Slider = ({sliders}) => (
  <Carousel autoplay style={{ background: "red", paddingTop: -50 }}>
    {sliders.map((item) => {
      return (
        <div key={item.id}>
          <div
            style={{
              height: "500px",

              color: "#fff",

              backgroundImage: `url(${Constants.URL}/assets/${item.slider.filename_disk})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          ></div>
        </div>
      );
    }) }
   
   
  </Carousel>
);
export default Slider;
